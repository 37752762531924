<template>
    <div class="emptyPage">
        <div class="emptyHeader"  @click="goOther">
            <img class="poster" :src="sysInfo.posterUrl" alt="">
        </div>
        <div class="emptyBody">
            <img class="emptyImg" src="../../assets/img/emptyImg.png" alt="">
            <div class="title">{{sysInfo.title}}</div>
            <div class="desc">{{sysInfo.content}}</div>
        </div>

    </div>
</template>
<script >

export default {
    props:{
        sysInfo:{
            type:Object
        },
    },
    data(){
        return {}
    },
    methods:{
        close(){
            window.close()
        },
         goOther(){
            if(!!this.sysInfo.jumpUrl){
                window.open(this.sysInfo.jumpUrl)
            }
        }
    }
}
</script>
<style lang="less" scoped>
.emptyPage{
    width: 75%;
    min-height: 100vh;
    margin: auto;
    padding-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    .emptyHeader{
        width: 100%;
        min-height: 123px;
        .poster{
            width: 100%;
            height: auto;
        }
    }
    .emptyBody{
       margin-top: 70px;
       display: flex;
       width: 100%;
       align-items: center;
       flex-direction: column; 
       padding: 0px 40px;
       box-sizing: border-box;
       text-align: center;
       .emptyImg{
            width: 220px;
            height: 220px;
            font-weight: bold;
            cursor: pointer;
       }
       .title{
            padding:0px 150px;
           margin-top: 40px;
           font-size: 20px;
       }
       .desc{
        font-size: 16px;
        padding:0px 112px;
        margin-top: 25px;
        color: #666666;
       }
    }
    .emptyFooter{
        position: fixed;
        z-index: 1;
        width: 100%;
        bottom: 20px;
        left: 0px;
        .btn{
            margin: auto;
            width: 347px;
            height: 40px;
            background: #3C8CF0;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
        }
    }
}
</style>